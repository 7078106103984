import ReactBreadcrumb from "@/components/breadcrumb/Breadcrumb";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { toast } from "@/components/ui/use-toast";
import { useModal, useProjectStore } from "@/dashboard/store/store";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import { useDatabase } from "@/programs/hooks/useDatabase";
import { ProjectType } from "@/types";
import { FormEvent, lazy, Suspense, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddProject from "./AddProject";

const ReportingDetail = lazy(() =>
  import("@/dashboard/components/project/tabs/ReportingDetail").then(
    (module) => ({ default: module.default })
  )
);
const DescriptionDetail = lazy(() =>
  import("@/dashboard/components/project/tabs/DescriptionDetail").then(
    (module) => ({ default: module.default })
  )
);
const PerformanceDetail = lazy(() =>
  import("@/dashboard/components/project/tabs/PerformanceDetail").then(
    (module) => ({ default: module.default })
  )
);

// Tab configuration
const tabsConfig = [
  {
    value: "descriptif",
    label: "Descriptif du projet",
    component: DescriptionDetail,
  },
  // { value: "terrain", label: "Terrain rattaché", component: TerrainDetail },
  {
    value: "perf",
    label: "Suivi de performance ESG",
    component: PerformanceDetail,
  },
  { value: "reporting", label: "Reporting", component: ReportingDetail },
];

const DashboardProject = () => {
  const { id } = useParams<{ id: string }>();
  const { project, setProject } = useProjectStore();
  const { data, loading, error } = useFetchDataV2<ProjectType>(
    `${import.meta.env.VITE_API_ENDPOINT}/project/${id}`,
    id
  );
  const { setOpenModal } = useModal();
  const { updateProgramInDB } = useDatabase();
  const navigate = useNavigate();
  // Obtenir les paramètres de l'URL actuelle
  const urlParams = new URLSearchParams(window.location.search);
  // Récupérer la valeur du paramètre 'tab'
  const tabValue = urlParams.get("tab");
  const [reloadData, setReloadData] = useState(false);
  const handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    projectsTemp: any,
    projectsTempBdd: any
  ) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setReloadData(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const projectsArray: any[] = [];
    projectsTemp.map((project: any) => {
      if (project.type) {
        projectsArray.push({
          name: project.name,
          type: project.type,
        });
      }
    });
    const projectsArrayBdd: any[] = [];
    projectsTempBdd?.map((project: any) => {
      if (project.type) {
        projectsArrayBdd.push({
          name: project.name,
          type: project.type,
        });
      }
    });

    const program = await updateProgramInDB(
      { projects: [...projectsArrayBdd] },
      Number(project?.program.id)
    );
    toast({
      title: "Vos informations sont bien prises en compte.",
    });
    setOpenModal({ name: "project", value: false });
    if (program) {
      navigate(
        "/dashboard/project/" +
          program?.projects[program?.projects.length - 1].id
      );
    }
  };
  const defaultDataPost = {
    name: project?.name || "",
    description: project?.description || "",
    type: project?.type?.id || 0,
    expectedDuration: project?.expectedDuration || 0,
    launchDate: project?.launchDate || "",
    objective: project?.objective || "",
    actions: project?.actions || "",
    benefits: project?.benefits || "",
    criteria: project?.criteria || "",
    manager: project?.manager || {
      email: "",
      firstname: "",
      lastname: "",
      logo: {
        id: 0,
        name: "",
        type: 0,
        uri: "",
      },
      society: "",
      phone: "",
    },
    country: project?.country?.id || 0,
    plantedSpecies: Array.isArray(project?.plantedSpecies)
      ? project.plantedSpecies.map((item) => ({
          name: item.species.name,
        }))
      : [],
    protectedSpecies: Array.isArray(project?.protectedSpecies)
      ? project.protectedSpecies.map((item) => ({
          name: item.species.name,
        }))
      : [],
    peopleInvolved: project?.peopleInvolved || 0,
    fundRecipient: project?.fundRecipient || {
      email: "",
      name: "",
    },
    fundLocal: project?.fundLocal || {
      email: "",
      name: "",
    },
    trees: project?.trees || 0,
    surface: project?.surface || 0,
    sau: project?.sau?.toString() || "0",
    linear: project?.longHaie?.toString() || "0",
    location: project?.location || [],
    parcel: project?.parcel || [],
  };
  const [postData, setPostData] = useState(defaultDataPost);
  useEffect(() => {
    if (project) {
      setPostData(defaultDataPost);
      localStorage.setItem("currency_verdeo", project?.country?.id || 0);
    }
  }, [project]);
  useEffect(() => {
    if (data && !loading) {
      setProject(data); // Mettre à jour le store avec le nouveau projet
    }
  }, [data, loading, setProject]);

  if (loading) return <LoadingSpinner />;
  if (error)
    return <p className="text-red-500">Une erreur est survenue : {error}</p>;

  if (!project) return <p className="text-gray-500">Aucun projet trouvé.</p>;

  return (
    <div>
      <ReactBreadcrumb
        items={[
          { href: "/dashboard/programs", name: "Mes programmes" },
          {
            href: `/dashboard/program/${project.program?.id}`,
            name: project.program?.name || "",
          },
          { href: "", name: project.name || "" },
        ]}
      />
      <h1 className="my-4 text-2xl font-medium">
        Projet : {project?.status} - {project?.name}
      </h1>
      {project?.description && (
        <p
          className="mb-4 mt-2 text-[#7a7a7a]"
          dangerouslySetInnerHTML={{
            __html: project?.description.replace(/\n/g, "<br />"),
          }}
        />
      )}
      <Tabs defaultValue={tabValue || "descriptif"} className="w-full">
        <TabsList className="mb-2 grid w-full grid-cols-3 bg-background">
          {tabsConfig.map((tab, key: number) => (
            <TabsTrigger
              aria-label={tab.label}
              key={tab.value}
              value={tab.value}
              className={`rounded-none border-b-2 text-[#626262] data-[state=active]:border-foreground data-[state=active]:shadow-none ${
                key >= 1 && project.status != 3
                  ? "pointer-events-none cursor-context-menu opacity-20"
                  : ""
              }`}
            >
              {tab.label}
            </TabsTrigger>
          ))}
        </TabsList>

        {tabsConfig.map((tab, key) => {
          // Si le status du projet est 3, on affiche tous les éléments
          // Sinon, on affiche uniquement les éléments avec key <= 1
          const shouldRender = project?.status === 3 || key <= 1;

          return (
            shouldRender && (
              <TabsContent key={tab.value} value={tab.value} className="mb-4">
                <Suspense fallback={<LoadingSpinner />}>
                  <tab.component
                    postData={postData}
                    setPostData={setPostData}
                  />
                </Suspense>
              </TabsContent>
            )
          );
        })}
      </Tabs>

      <AddProject onHandleSubmit={handleSubmit} />
    </div>
  );
};

export default DashboardProject;
