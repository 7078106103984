import { File, Folder, Tree } from "@/components/extension/tree-view-api";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { projectStatus } from "@/constants/constants";
import { useProjectStore } from "@/dashboard/store/store";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import { ProgramType } from "@/types/dashboard";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { Check } from "lucide-react";
import { FC, useEffect, useMemo, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
const TreeView = () => {
  const { project } = useProjectStore();
  const {
    data: programs,
    loading,
    error,
  } = useFetchDataV2<ProgramType[]>(
    `${import.meta.env.VITE_API_ENDPOINT}/programs`,
    `programs-${project?.id}` // Clé dynamique
  );
  const types = useFetchDataV2(
    `${import.meta.env.VITE_API_ENDPOINT}/project-types`,
    "types"
  );
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const [currentTab, setCurrentTab] = useState("descriptif");
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const stepParams = searchParams.get("step");
  const currentStep = stepParams ? parseInt(stepParams) : 1;
  const [fields, setFields] = useState([]);
  const navigate = useNavigate();
  const currentPage = useMemo(() => {
    const page = window.location.pathname;
    return page.includes("program")
      ? "program"
      : page.includes("project")
      ? "project"
      : "other";
  }, []);

  // Le variables elements sert a idéntifié quel élément on va ouvrir par défaut
  const elements = [
    {
      id: "1",
      isSelectable: true,
      name: "Mes programmes",
      children: programs
        ?.map((item: ProgramType, key: number) => {
          return {
            id: (key + 10).toString(),
            isSelectable: true,
            name: item.name,
            children: item?.projects
              ?.map((project: { name: string }, k: number) => {
                return {
                  id: (k + 20).toString(),
                  isSelectable: true,
                  name: project.name,
                };
              })
              .filter(Boolean),
          };
        })
        .filter(Boolean),
    },
  ];

  useEffect(() => {
    if (tab) {
      setCurrentTab(tab);
    }
  }, [tab]);
  useEffect(() => {
    if (types?.data) {
      const fields: [] = [];
      types?.data["hydra:member"].map(function (pt: {
        id: number;
        fields: any;
      }) {
        if (pt.id === project?.type) {
          fields.push(pt.fields.map((field: { name: string }) => field.name));
        }
      });
      if (fields.length > 0) {
        setFields(fields[0]);
      }
    }
  }, [types, project]);
  // Calculer l'ID initial sélectionné
  const initialSelectedId = useMemo(() => {
    if (!id || !programs) return "1";
    const programIndex = programs.findIndex((p) => p.id == Number(id));
    return currentPage === "program"
      ? (programIndex + 10).toString()
      : currentPage === "project"
      ? (
          programs.findIndex(
            (p) => p.id == findProgramByProjectId(Number(id), programs)
          ) + 10
        ).toString()
      : "1";
  }, [id, programs, currentPage]);
  console.log("initialSelectedId", initialSelectedId);
  // Invalidate les données de `programs` à chaque changement de projet
  useEffect(() => {
    if (project?.id) {
      queryClient.invalidateQueries({ queryKey: [`programs-${project.id}`] });
    }
  }, [project, queryClient]);
  // Gestion du chargement et des erreurs
  if (loading) return <LoadingSpinner />;
  if (error) return <div>Une erreur est survenue: {error}</div>;

  return (
    <Tree
      initialSelectedId={initialSelectedId}
      elements={elements}
      className="mb-4 "
    >
      <Folder element="Mes programmes" value="1" visibleChevron={true}>
        <div className="flex flex-col gap-1">
          {programs?.map((item: ProgramType, key: number) => (
            <Folder
              element={item.name}
              value={(key + 10).toString()}
              key={key}
              visibleChevron={true}
              projectId={item.id}
              className={`${
                item.id.toString() == id ? "bg-primary/10" : ""
              } py-1`}
            >
              {item.projects.map((p: any, k: number) => (
                <Folder
                  key={p.id}
                  value={(k + 20).toString()}
                  element={p.name}
                  className={`w-full items-start text-left ${(
                    k + 20
                  ).toString()}`}
                  projectId={p.id}
                  visibleChevron={true}
                >
                  {/* Descriptif du projet */}
                  <File
                    value={(k + 21).toString()}
                    fileIcon=""
                    className="w-full"
                  >
                    <StepMenuTab
                      title={t("Descriptif")}
                      link={`/dashboard/project/${p.id}?tab=descriptif`}
                      currentTab={currentTab}
                      tab="descriptif"
                    />
                  </File>

                  <File value={(k + 22).toString()} fileIcon="">
                    <StepMenu
                      title={t("Détails du projet")}
                      onClick={() => {
                        navigate(`/dashboard/project/${p.id}?step=1`);
                      }}
                      currentStep={currentStep}
                      step={1}
                      state={p.step >= 1}
                      stepLabel="1"
                    />
                  </File>
                  <File
                    value={(k + 23).toString()}
                    fileIcon=""
                    className={
                      p.step == null || p.step < 2
                        ? "pointer-events-none opacity-50"
                        : ""
                    }
                  >
                    <StepMenu
                      title={t("Objectifs du projet")}
                      onClick={() => {
                        navigate(`/dashboard/project/${p.id}?step=2`);
                      }}
                      currentStep={currentStep}
                      step={2}
                      state={p.step >= 2}
                      stepLabel="2"
                    />
                  </File>
                  <File
                    value={(k + 24).toString()}
                    fileIcon=""
                    className={
                      p.step == null || p.step < 3
                        ? "pointer-events-none opacity-50"
                        : ""
                    }
                  >
                    <StepMenu
                      title={t("Financements")}
                      onClick={() => {
                        navigate(`/dashboard/project/${p.id}?step=3`);
                      }}
                      currentStep={currentStep}
                      step={3}
                      state={p.step >= 3}
                      stepLabel="3"
                    />
                  </File>
                  {(fields.includes("peopleInvolved") ||
                    fields.includes("plantedSpecies") ||
                    fields.includes("protectedSpecies")) && (
                    <File
                      value={(k + 25).toString()}
                      fileIcon=""
                      className={
                        p.step == null || p.step < 4
                          ? "pointer-events-none opacity-50"
                          : ""
                      }
                    >
                      <StepMenu
                        title={t("Autres informations")}
                        onClick={() => {
                          navigate(`/dashboard/project/${p.id}?step=4`);
                        }}
                        currentStep={currentStep}
                        step={4}
                        state={p.step >= 4}
                        stepLabel="4"
                      />
                    </File>
                  )}
                  <File
                    value={(k + 26).toString()}
                    fileIcon=""
                    className={
                      p.step == null || p.step < 5
                        ? "pointer-events-none opacity-50"
                        : ""
                    }
                  >
                    <StepMenu
                      title={t("Parcelle et localisation")}
                      onClick={() => {
                        navigate(`/dashboard/project/${p.id}?step=5`);
                      }}
                      currentStep={currentStep}
                      step={5}
                      state={p.step >= 5}
                      stepLabel={
                        fields.includes("peopleInvolved") ||
                        fields.includes("plantedSpecies") ||
                        fields.includes("protectedSpecies")
                          ? "5"
                          : "4"
                      }
                    />
                  </File>
                  <File
                    value={(k + 27).toString()}
                    fileIcon=""
                    className={
                      p.step == null || p.step < 6
                        ? "pointer-events-none opacity-50"
                        : ""
                    }
                  >
                    <StepMenu
                      title={t("Pièces jointes")}
                      onClick={() => {
                        navigate(`/dashboard/project/${p.id}?step=6`);
                      }}
                      currentStep={currentStep}
                      step={6}
                      state={p.step >= 6}
                      stepLabel={
                        fields.includes("peopleInvolved") ||
                        fields.includes("plantedSpecies") ||
                        fields.includes("protectedSpecies")
                          ? "6"
                          : "5"
                      }
                    />
                  </File>
                  {/* Suivi de perfo */}
                  <File
                    value={(k + 28).toString()}
                    fileIcon=""
                    className={`${
                      p.status !== projectStatus.Validated
                        ? "pointer-events-none opacity-25"
                        : ""
                    }`}
                  >
                    <StepMenuTab
                      title={t("Suivi de performance ESG")}
                      link={`/dashboard/project/${p.id}?tab=suivi-perf`}
                      currentTab={currentTab}
                      tab="suivi-perf"
                    />
                  </File>
                  {/* Reporting */}
                  <File
                    value={(k + 29).toString()}
                    fileIcon=""
                    className={`${
                      p.status !== projectStatus.Validated
                        ? "pointer-events-none opacity-25"
                        : ""
                    }`}
                  >
                    <StepMenuTab
                      title={t("Reporting")}
                      link={`/dashboard/project/${p.id}?tab=reporting`}
                      currentTab={currentTab}
                      tab="reporting"
                    />
                  </File>
                </Folder>
              ))}
            </Folder>
          ))}
        </div>
      </Folder>
    </Tree>
  );
};

const StepMenu: FC<
  {
    title: string;
    currentStep: number;
    step: number;
    state: boolean;
    stepLabel?: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ title, currentStep, step, state, stepLabel, ...props }) => {
  return (
    <div className={`relative ml-5`} {...props}>
      <div className={`flex items-start rounded-md py-1 pl-0 text-left`}>
        <p className="mb-0 flex items-center text-[13px]">
          {state ? (
            <span
              className={`mr-2 flex size-6 items-center justify-center ${
                currentStep == step
                  ? "bg-primary text-background"
                  : "bg-primary/15"
              } rounded-full`}
            >
              <Check size={15} />{" "}
            </span>
          ) : (
            stepLabel && (
              <span
                className={`mr-2 flex size-6 items-center justify-center text-[13px] ${
                  currentStep == step
                    ? "bg-primary text-background"
                    : "bg-primary/15 text-foreground"
                }  rounded-full`}
              >
                {stepLabel}
              </span>
            )
          )}
          <span
            className={`flex-1 text-left text-[13px] ${
              currentStep >= step ? "font-semibold" : ""
            } ${
              !stepLabel
                ? "rounded-sm bg-primary/15 px-2 py-1 font-semibold"
                : ""
            } line-clamp-2`}
          >
            {title}
          </span>
        </p>
      </div>
    </div>
  );
};

const StepMenuTab: FC<
  {
    title: string;
    link: string;
    currentTab: string;
    tab: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ title, link, currentTab, tab, ...props }) => {
  return (
    <div className={`relative ml-3 w-full`} {...props}>
      <Link
        to={link}
        className={`flex w-full items-start rounded-md py-1 pl-0 text-left`}
      >
        <p className="mb-0 flex w-full items-center text-[13px]">
          <span
            className={`line-clamp-2 w-full rounded-sm ${
              currentTab == tab ? "bg-primary/15 font-semibold" : ""
            } px-2 py-1 text-left text-[13px] `}
          >
            {title}
          </span>
        </p>
      </Link>
    </div>
  );
};
// Fonction pour trouver le programme en fonction de l'ID du projet
function findProgramByProjectId(projectId: number, data: ProgramType[]) {
  const program = data.find((p: ProgramType) =>
    p.projects.some((project) => project.id === projectId)
  );
  return program ? program.id : null;
}
export default TreeView;
