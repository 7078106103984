import ReactBreadcrumb from "@/components/breadcrumb/Breadcrumb";
import { Button } from "@/components/ui/button";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { toast } from "@/components/ui/use-toast";
import ProjectList from "@/dashboard/components/project/ProjectList";
import { useModal } from "@/dashboard/store/store";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import { useDatabase } from "@/programs/hooks/useDatabase";
import { ProgramType } from "@/types/dashboard";
import { FormEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddProject from "./AddProject";

const DashboardProgram = () => {
  const { setOpenModal } = useModal();
  const { updateProgramInDB } = useDatabase();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [reloadData, setReloadData] = useState(false);
  const handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    projectsTemp: any,
    projectsTempBdd: any
  ) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setReloadData(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const projectsArray: any[] = [];
    projectsTemp.map((project: any) => {
      if (project.type) {
        projectsArray.push({
          name: project.name,
          type: project.type,
        });
      }
    });
    const projectsArrayBdd: any[] = [];
    projectsTempBdd?.map((project: any) => {
      if (project.type) {
        projectsArrayBdd.push({
          name: project.name,
          type: project.type,
        });
      }
    });

    const program = await updateProgramInDB(
      { projects: [...projectsArrayBdd] },
      Number(id)
    );
    toast({
      title: "Vos informations sont bien prises en compte.",
    });
    setOpenModal({ name: "project", value: false });

    navigate(
      "/dashboard/project/" + program?.projects[program?.projects.length - 1].id
    );
  };
  const { data, loading, error } = useFetchDataV2<ProgramType>(
    `${import.meta.env.VITE_API_ENDPOINT}/program/${id}`
  );

  error && new Error("Une erreur est survenue: " + error);
  return (
    <div>
      <ReactBreadcrumb
        items={[
          {
            href: "/dashboard/programs",
            name: "Mes programmes",
          },
          {
            href: ``,
            name: data?.name || "...",
          },
        ]}
      />
      <h1 className="mb-5 mt-4 text-2xl font-medium">Mes projets</h1>

      {loading && <LoadingSpinner />}

      {data && data.projects.length > 0 ? (
        <ProjectList projects={data.projects} />
      ) : (
        !loading && (
          <div>
            Il n'y a pas encore de projets dans votre programme. Veuillez en
            ajouter un en cliquant sur le bouton "Ajouter".
          </div>
        )
      )}
      {/* On affiche le bouton "ajouter" qui est en dehors du composant ProjectList quand il n'y a pas de projet afin d'en ajouter un */}
      {data?.projects.length == 0 && (
        <div className="mb-4 mt-5 h-full min-w-52  sm:w-1/2 sm:max-w-[50%] md:w-1/3 md:max-w-[33%] lg:w-1/3 lg:max-w-[33%] xl:w-1/4 xl:max-w-[25%]">
          <Button
            variant="outline"
            className=" flex size-full h-[170px] items-center justify-center rounded-[15px] border"
            style={{ background: "#EFEFEF" }}
            onClick={() => setOpenModal({ name: "project", value: true })}
          >
            <div className="flex w-max  items-center">
              <svg
                width="48"
                height="36"
                viewBox="0 0 48 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.61257 36C3.34411 36 2.25863 35.5597 1.35609 34.6793C0.452031 33.7973 0 32.7375 0 31.5V4.5C0 3.2625 0.452031 2.20351 1.35609 1.323C2.25863 0.441 3.34411 0 4.61257 0H16.5476C17.1626 0 17.7492 0.1125 18.3073 0.3375C18.8639 0.5625 19.3536 0.881249 19.7765 1.29375L23.0628 4.5H41.5131C42.7815 4.5 43.8679 4.941 44.772 5.823C45.6744 6.70351 46.1257 7.7625 46.1257 9H21.1602L16.5476 4.5H4.61257V31.5L9.16747 16.7062C9.47498 15.7312 10.0423 14.9527 10.8695 14.3707C11.6952 13.7902 12.6077 13.5 13.6071 13.5H43.3581C44.9341 13.5 46.1742 14.109 47.0782 15.327C47.9806 16.5465 48.2205 17.8687 47.7976 19.2938L43.6463 32.7938C43.3389 33.7687 42.7723 34.5472 41.9467 35.1293C41.1195 35.7098 40.2063 36 39.2068 36H4.61257ZM9.45576 31.5H39.2068L43.3581 18H13.6071L9.45576 31.5Z"
                  fill="#D4D4D4"
                />
                <rect
                  x="25"
                  y="19"
                  width="3"
                  height="11"
                  rx="1"
                  fill="#D9D9D9"
                />
                <rect
                  x="32"
                  y="23"
                  width="3"
                  height="11"
                  rx="1"
                  transform="rotate(90 32 23)"
                  fill="#D9D9D9"
                />
              </svg>
            </div>
          </Button>
          <div className=" mt-4 flex cursor-pointer items-center gap-2 font-semibold text-foreground opacity-70">
            Ajouter
          </div>
        </div>
      )}

      <AddProject onHandleSubmit={handleSubmit} />
    </div>
  );
};

export default DashboardProgram;
