import * as turf from "@turf/turf";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { useEffect, useRef, useState } from "react";
import "./MapPrograms.css";

const MapClusterPrograms = ({ programs }) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [activeMarkers, setActiveMarkers] = useState([]);

  useEffect(() => {
    if (mapContainerRef.current) {
      const map = new maplibregl.Map({
        container: mapContainerRef.current,
        style:
          "https://tiles.stadiamaps.com/styles/alidade_smooth.json?api_key=" +
          import.meta.env.VITE_API_KEY_STADIAMAP,
        center: [0, 0],
        zoom: 2,
      });

      map.addControl(new maplibregl.NavigationControl());
      setMapInstance(map);

      const markerCollection = {
        type: "FeatureCollection",
        features: [],
      };

      programs.forEach((program) => {
        program.projects.forEach((project) => {
          const allCoordinates = [];

          // Récupérer les locations et parcels
          if (project.location) {
            project.location.forEach((coordinates) => {
              coordinates.forEach((coord) => {
                allCoordinates.push([coord[1], coord[0]]);
              });
            });
          }
          if (project.parcel) {
            project.parcel.forEach((parcel) => {
              allCoordinates.push(...parcel.coordinates);
            });
          }

          // Clusteriser les coordonnées par proximité
          if (allCoordinates.length > 0) {
            const points = turf.featureCollection(
              allCoordinates.map((coord) =>
                turf.point(coord, { projectName: project.name })
              )
            );

            // Regrouper en clusters (seuil : 100 km)
            const clusters = [];
            points.features.forEach((point) => {
              let added = false;
              for (const cluster of clusters) {
                const dist = turf.distance(
                  turf.center(turf.featureCollection(cluster)),
                  point,
                  { units: "kilometers" }
                );
                if (dist <= 100) {
                  cluster.push(point);
                  added = true;
                  break;
                }
              }
              if (!added) {
                clusters.push([point]);
              }
            });

            // Ajouter chaque cluster comme un centre distinct
            clusters.forEach((cluster) => {
              const clusterCenter = turf.center(
                turf.featureCollection(cluster)
              );
              markerCollection.features.push({
                type: "Feature",
                geometry: clusterCenter.geometry,
                properties: {
                  title: `Cluster: ${project.name}`,
                  coordinates: cluster.map((c) => c.geometry.coordinates),
                },
              });
            });
          }
        });
      });

      // Ajouter les marqueurs principaux
      markerCollection.features.forEach((feature) => {
        const el = document.createElement("div");
        el.className = "marker";
        el.style.width = "20px";
        el.style.height = "20px";
        el.style.backgroundColor = "red";
        el.style.borderRadius = "50%";

        const marker = new maplibregl.Marker(el)
          .setLngLat(feature.geometry.coordinates)
          .setPopup(
            new maplibregl.Popup({ offset: 25 }).setHTML(
              `<div>${feature.properties.title}</div>`
            )
          )
          .addTo(map);

        marker.getElement().addEventListener("click", () => {
          displayClusterMarkers(feature.properties.coordinates, map);
        });
      });

      map.on("load", () => {
        map.setPaintProperty("water", "fill-color", "#75CFF0");
        map.setPaintProperty("background", "background-color", "#E7EBCC");
        map.setPaintProperty("building", "fill-color", "#E7EBCC");
      });
      return () => {
        map.remove();
        setMapInstance(null);
      };
    }
  }, [programs]);

  const displayClusterMarkers = (coordinates, map) => {
    // Supprimer les anciens marqueurs
    activeMarkers.forEach((marker) => marker.remove());
    setActiveMarkers([]);

    // Ajouter les nouveaux marqueurs
    const markers = coordinates.map((coord) => {
      const el = document.createElement("div");
      el.className = "marker";
      el.style.width = "10px";
      el.style.height = "10px";
      el.style.backgroundColor = "blue";
      el.style.borderRadius = "50%";

      return new maplibregl.Marker(el).setLngLat(coord).addTo(map);
    });

    setActiveMarkers(markers);

    // Zoomer pour inclure tous les marqueurs
    const bounds = new maplibregl.LngLatBounds();
    coordinates.forEach((coord) => bounds.extend(coord));
    map.fitBounds(bounds, {
      padding: 50,
      duration: 1000,
    });

    // Supprimer les marqueurs quand on dézoome
    const handleZoomEnd = () => {
      if (map.getZoom() < 10) {
        markers.forEach((marker) => marker.remove());
        setActiveMarkers([]);
        map.off("zoomend", handleZoomEnd); // Nettoyage
      }
    };

    // Attacher l'événement zoomend
    map.on("zoomend", handleZoomEnd);
  };

  return (
    <div
      ref={mapContainerRef}
      style={{ height: "75vh", width: "100%", zIndex: "1" }}
    />
  );
};

export default MapClusterPrograms;
//probleme icon
