import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App.tsx";
import "./i18n.tsx";
import { ProgramProvider } from "./programs/context/programContext.tsx";
import { TypeProjectProvider } from "./programs/context/typeProgramContext.tsx";

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <BrowserRouter future={{
    v7_startTransition: true,
    v7_relativeSplatPath: true,
  }}
>
    <QueryClientProvider client={queryClient}>
      <ProgramProvider>
        <TypeProjectProvider>
          <App />
          {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
        </TypeProjectProvider>
      </ProgramProvider>
    </QueryClientProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
