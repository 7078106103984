import { Button } from "@/components/ui/button";
import { useModal } from "@/dashboard/store/store";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import ProjectSummary from "./ProjectSummary";

const ProjectList: FC<{ projects: any }> = ({ projects }) => {
  const navigate = useNavigate();
  const { setOpenModal } = useModal();
  return (
    <div>
      <div className="-mx-4 mt-8 flex w-full flex-wrap items-start">
        {projects?.map((project: any, key: number) => (
          <div
            className="mb-4 min-w-52  px-4 sm:w-1/2 sm:max-w-[50%] md:w-1/3 md:max-w-[33%] lg:w-1/3 lg:max-w-[33%] xl:w-1/4 xl:max-w-[25%] "
            key={key}
          >
            <ProjectSummary
              key={key}
              id={project.id}
              type={project.type}
              picture={
                project?.defaultImage
                  ? import.meta.env.VITE_MEDIA_BASE + project?.defaultImage?.uri
                  : null
              }
              title={project.name}
              link={`?project=${project.id}`}
              status={project.status}
              onSelect={() => navigate(`/dashboard/project/${project.id}`)}
              typeProjectImage=""
            />
          </div>
        ))}
        <div className="mb-4 h-full min-w-52 px-4 sm:w-1/2 sm:max-w-[50%] md:w-1/3 md:max-w-[33%] lg:w-1/3 lg:max-w-[33%] xl:w-1/4 xl:max-w-[25%]">
          <Button
            variant="outline"
            className=" flex size-full h-[170px] items-center justify-center rounded-[15px] border"
            style={{ background: "#EFEFEF" }}
            onClick={() => setOpenModal({ name: "project", value: true })}
          >
            <div className="flex w-max  items-center">
              <svg
                width="48"
                height="36"
                viewBox="0 0 48 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.61257 36C3.34411 36 2.25863 35.5597 1.35609 34.6793C0.452031 33.7973 0 32.7375 0 31.5V4.5C0 3.2625 0.452031 2.20351 1.35609 1.323C2.25863 0.441 3.34411 0 4.61257 0H16.5476C17.1626 0 17.7492 0.1125 18.3073 0.3375C18.8639 0.5625 19.3536 0.881249 19.7765 1.29375L23.0628 4.5H41.5131C42.7815 4.5 43.8679 4.941 44.772 5.823C45.6744 6.70351 46.1257 7.7625 46.1257 9H21.1602L16.5476 4.5H4.61257V31.5L9.16747 16.7062C9.47498 15.7312 10.0423 14.9527 10.8695 14.3707C11.6952 13.7902 12.6077 13.5 13.6071 13.5H43.3581C44.9341 13.5 46.1742 14.109 47.0782 15.327C47.9806 16.5465 48.2205 17.8687 47.7976 19.2938L43.6463 32.7938C43.3389 33.7687 42.7723 34.5472 41.9467 35.1293C41.1195 35.7098 40.2063 36 39.2068 36H4.61257ZM9.45576 31.5H39.2068L43.3581 18H13.6071L9.45576 31.5Z"
                  fill="#D4D4D4"
                />
                <rect
                  x="25"
                  y="19"
                  width="3"
                  height="11"
                  rx="1"
                  fill="#D9D9D9"
                />
                <rect
                  x="32"
                  y="23"
                  width="3"
                  height="11"
                  rx="1"
                  transform="rotate(90 32 23)"
                  fill="#D9D9D9"
                />
              </svg>
            </div>
          </Button>
          <div className="text-md mt-4 flex cursor-pointer items-center gap-2 font-semibold text-foreground opacity-70">
            Ajouter
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectList;
