import CardTypeProject from "@/components/card/CardTypeProject";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useModal } from "@/dashboard/store/store";
import useCheckIsDevice from "@/hooks/useDeviceType";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import i18n from "@/i18n";
import { CategoryProjectType } from "@/types";
import { FC, FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const AddProject: FC<{
  onHandleSubmit: (
    e: FormEvent<HTMLFormElement>,
    projectsTemp: any,
    setProjectsTemp: (item: any[]) => void
  ) => void;
}> = ({ onHandleSubmit }) => {
  const { setOpenModal, modals } = useModal();
  const [projectsTemp, setProjectsTemp] = useState<any[]>([]);
  const [projectsTempBdd, setProjectsTempBdd] = useState<any[]>([]);
  const defaultClass =
    "relative cursor-pointer rounded-[10px] font-[600] text-[#2D3720] bg-[rgba(194,194,194,0.1)] py-[10px] px-[15px]";
  const environnementalClass =
    "relative cursor-pointer rounded-[10px] font-[600] text-[#80B533] bg-[#f2f7ec]  py-[10px] px-[15px]";
  const socialClass =
    "relative cursor-pointer rounded-[10px] font-[600] text-[#FF9500] bg-[#fff5ea]  py-[10px] px-[15px]";
  const projectType = useFetchDataV2(
    `${import.meta.env.VITE_API_ENDPOINT}/project-types`
  );
  // Vérifier si 'data' est un tableau avant de le passer au contexte
  const typesProject =
    typeof projectType.data == "object" && projectType.data
      ? projectType.data["hydra:member"]
      : null;

  const [projectsList, setProjectsList] = useState([]);

  const [filterActive, setFilterActive] = useState("all");
  const [typeActive, setTypeActive] = useState(1);
  const handleChangeType = (type) => {
    setTypeActive(type);

    setFilterActive("all");
    setProjectsList(typesProject.filter((project) => project.type == type));
  };
  const onHandleOpen = () => {
    setOpenModal({ name: "project", value: !modals["project"] });
  };

  useEffect(() => {
    if (typesProject) {
      setProjectsList(typesProject?.filter((i) => i.type == 1));
    }
  }, [typesProject]);

  const [projectTypeTexts, setProjectTypeTexts] = useState(
    typesProject?.map((pt) => {
      return {
        name: pt.projectTypeLangs.find((ptl) => ptl.lang.code === i18n.language)
          ?.name,
        description: pt.projectTypeLangs.find(
          (ptl) => ptl.lang.code === i18n.language
        )?.description,
        id: pt.id,
      };
    })
  );
  const itemsRef = useRef<HTMLDivElement[]>([]);

  const [parentTexts, setParentTexts] = useState(
    typesProject
      ?.map((item) => item.parent)
      .reduce((acc, parent) => {
        if (parent && !acc.some((existing) => existing.name === parent.name)) {
          acc.push(parent);
        }
        return acc;
      }, [])
      .map((pt) => {
        return {
          name: pt.projectTypeLangs.find(
            (ptl) => ptl.lang.code === i18n.language
          )?.name,
          id: pt.id,
        };
      })
  );

  const handleFilterChange = (item) => {
    const projectUpdated =
      item == "all"
        ? typesProject.filter((i) => i.type == typeActive)
        : typesProject.filter((i) => {
            return i.parent.name == item && i.type == typeActive;
          });
    setProjectsList(projectUpdated);
    setFilterActive(item);
  };

  const handleChange = (item: string) => {
    const itemProject = typesProject?.find((project) => project.name === item);
    if (
      itemProject &&
      !projectsTemp.some((proj) => proj.name === itemProject.name)
    ) {
      setProjectsTemp([
        {
          name: itemProject.name,
          type: itemProject.id,
          icon: itemProject.icon,
        },
      ]);
      setProjectsTempBdd([
        {
          name: itemProject.name,
          type: itemProject.id,
          icon: itemProject.icon,
        },
      ]);
    }
  };

  const handleRemoveChange = (item: string) => {
    setProjectsTemp(projectsTemp.filter((project) => project.name != item));
    setProjectsTempBdd(
      projectsTempBdd.filter((project) => project.name != item)
    );
  };
  return (
    <div className="h-[170px] w-1/4 pr-8" id="addTypes">
      <Dialog open={modals["project"]} onOpenChange={onHandleOpen}>
        {/* <DialogTrigger asChild>
          <div className="flex size-full flex-col items-start justify-center">
            <Button
              variant="outline"
              className=" flex size-full items-center justify-center rounded-[15px] border"
              style={{ background: "#EFEFEF" }}
            >
              <div className="flex w-max  items-center">
                <svg
                  width="48"
                  height="36"
                  viewBox="0 0 48 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.61257 36C3.34411 36 2.25863 35.5597 1.35609 34.6793C0.452031 33.7973 0 32.7375 0 31.5V4.5C0 3.2625 0.452031 2.20351 1.35609 1.323C2.25863 0.441 3.34411 0 4.61257 0H16.5476C17.1626 0 17.7492 0.1125 18.3073 0.3375C18.8639 0.5625 19.3536 0.881249 19.7765 1.29375L23.0628 4.5H41.5131C42.7815 4.5 43.8679 4.941 44.772 5.823C45.6744 6.70351 46.1257 7.7625 46.1257 9H21.1602L16.5476 4.5H4.61257V31.5L9.16747 16.7062C9.47498 15.7312 10.0423 14.9527 10.8695 14.3707C11.6952 13.7902 12.6077 13.5 13.6071 13.5H43.3581C44.9341 13.5 46.1742 14.109 47.0782 15.327C47.9806 16.5465 48.2205 17.8687 47.7976 19.2938L43.6463 32.7938C43.3389 33.7687 42.7723 34.5472 41.9467 35.1293C41.1195 35.7098 40.2063 36 39.2068 36H4.61257ZM9.45576 31.5H39.2068L43.3581 18H13.6071L9.45576 31.5Z"
                    fill="#D4D4D4"
                  />
                  <rect
                    x="25"
                    y="19"
                    width="3"
                    height="11"
                    rx="1"
                    fill="#D9D9D9"
                  />
                  <rect
                    x="32"
                    y="23"
                    width="3"
                    height="11"
                    rx="1"
                    transform="rotate(90 32 23)"
                    fill="#D9D9D9"
                  />
                </svg>
              </div>
            </Button>
            <div className="text-md mt-4 flex cursor-pointer items-center gap-2 font-semibold text-foreground opacity-70">
              Ajouter
            </div>
          </div>
        </DialogTrigger> */}
        <DialogContent className="sm:h-[95vh] sm:max-w-[80vw] sm:overflow-hidden ">
          <DialogHeader className="justify-around">
            <DialogTitle asChild>
              <h2 className="mb-2 mt-5 flex items-center justify-center gap-4 text-center text-xl font-bold">
                Typologie des projets
                {/* {isMobile && (
                  <Info onClick={() => setInfoVisible(!infoVisible)} />
                )} */}
              </h2>
            </DialogTitle>
            <DialogDescription asChild className="text-[#595959]">
              <p className="mb-0  text-center sm:mx-auto sm:max-w-[60vw]">
                Chaque projet a une typologie unique, qui détermine les
                informations spécifiques à remplir. Un programme peut inclure
                plusieurs projets si celui-ci a plusieurs impacts. Chaque projet
                est conçu pour représenter un opérateur sur le terrain, afin de
                collecter les données le plus près possible de l'action. Si un
                projet couvre une vaste zone ou implique plusieurs intervenants,
                il peut être subdivisé en plusieurs projets pour une gestion
                plus précise.
              </p>
            </DialogDescription>
          </DialogHeader>

          <div className="flex gap-2">
            <div
              onClick={() => handleChangeType(1)}
              className={typeActive === 1 ? environnementalClass : defaultClass}
            >
              Type environnemental
              {typeActive === 1 && (
                <div
                  style={{ width: "calc(100% - 6px)" }}
                  className="absolute bottom-0 left-[3px] h-[3px] rounded-[2px] bg-[#80B533]"
                ></div>
              )}
            </div>
            <div
              onClick={() => handleChangeType(2)}
              className={typeActive === 2 ? socialClass : defaultClass}
            >
              Type social
              {typeActive === 2 && (
                <div
                  style={{ width: "calc(100% - 6px)" }}
                  className="absolute bottom-0 left-[3px] h-[3px] rounded-[2px] bg-[#FF9500]"
                ></div>
              )}
            </div>
          </div>

          <div
            className={`relative rounded-[20px] ${
              typeActive === 1 ? "bg-[#f2f7ec]" : "bg-[#fff5ea]"
            } p-[20px]`}
          >
            <div
              className={`${
                typeActive === 1 ? "bg-[#f2f7ec]" : "bg-[#fff5ea]"
              }`}
              style={{
                position: "absolute",
                top: "3px",
                left: typeActive === 1 ? "86px" : "246px",
                transform: "rotate(20deg)",
                width: "90px",
                height: "30px",
                zIndex: -1,
                borderRadius: "4px",
              }}
            ></div>
            <div
              className={`${
                typeActive === 1 ? "bg-[#f2f7ec]" : "bg-[#fff5ea]"
              }`}
              style={{
                position: "absolute",
                top: "3px",
                left: typeActive === 1 ? "15px" : "175px",
                transform: "rotate(-20deg)",
                width: "90px",
                height: "30px",
                zIndex: -1,
                borderRadius: "4px",
              }}
            ></div>
            <FilterProject
              projects={typesProject}
              parentTexts={parentTexts}
              onFilterChange={handleFilterChange}
              filterActive={filterActive}
              type={typeActive}
            />

            <form
              onSubmit={(e) => onHandleSubmit(e, projectsTemp, projectsTempBdd)}
            >
              <ScrollArea style={{ height: "calc(100vh - 430px)" }}>
                <div className="flex flex-wrap pt-4 sm:pb-52">
                  {projectType.loading ? (
                    <div className="flex h-14 w-full items-center justify-center">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    projectsList?.map(
                      (item: CategoryProjectType, index: number) => {
                        return (
                          <div
                            ref={(el) => (itemsRef.current[index] = el)}
                            className="relative mb-4 basis-full px-4 md:basis-1/5"
                            key={item.id}
                          >
                            <CardTypeProject
                              icon={item.icon}
                              name={
                                projectTypeTexts?.find(
                                  (ptt) => ptt.id === item.id
                                )?.name || item.name
                              }
                              description={
                                projectTypeTexts?.find(
                                  (ptt) => ptt.id === item.id
                                )?.description || item.description
                              }
                              isActive={projectsTemp.some(
                                (project) => project.name === item.name
                              )}
                              type={item.type}
                            />
                            <Checkbox
                              className="absolute left-4 top-0 z-30 h-full opacity-0"
                              style={{ width: "calc(100% - 30px)" }}
                              onCheckedChange={(checked: boolean) => {
                                if (checked) {
                                  handleChange(item.name);
                                } else {
                                  handleRemoveChange(item.name);
                                }
                              }}
                            />
                          </div>
                        );
                      }
                    )
                  )}
                </div>
              </ScrollArea>
              <div className="absolute bottom-0 left-0 z-50 flex w-full justify-center bg-gradient-to-t from-background to-background/0 py-8">
                <Button type="submit">Suivant</Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const FilterProject = ({
  projects,
  parentTexts,
  onFilterChange,
  filterActive,
  type,
}) => {
  const { t } = useTranslation("submit", {
    keyPrefix: "project",
  });
  const isMobile = useCheckIsDevice("mobile");
  const parentsProjects = projects
    ?.map((item) => item.parent)
    .reduce((acc, parent) => {
      if (parent && !acc.some((existing) => existing.name === parent.name)) {
        acc.push(parent);
      }
      return acc;
    }, [])
    .filter((parent) => parent.type === type);

  const classActive =
    type === 1
      ? "hover:bg-[#80B533] bg-[#80B533]"
      : "hover:bg-[#FF9500] bg-[#FF9500]";

  return (
    <>
      {isMobile ? (
        <Carousel
          opts={{
            align: "start",
          }}
          className="mx-auto "
        >
          <CarouselContent className="w-[60vw]">
            {parentsProjects?.map((item, key: number) => (
              <CarouselItem key={key} className="basis-2/3 pl-2">
                <Badge
                  variant={item.name == filterActive ? "default" : "outline"}
                  onClick={() => onFilterChange(item.name)}
                  className={
                    "w-full cursor-pointer px-3 " +
                    (item.name == filterActive ? classActive : "")
                  }
                >
                  {parentTexts?.find((ptt) => ptt.id === item.id)?.name ||
                    item.name}
                </Badge>
              </CarouselItem>
            ))}
            <CarouselItem className="basis-2/3 pl-2">
              <Badge
                onClick={() => onFilterChange("all")}
                variant={filterActive == "all" ? "default" : "outline"}
                className={
                  "cursor-pointer " + (filterActive == "all" ? classActive : "")
                }
              >
                {t("Toutes")}
              </Badge>
            </CarouselItem>
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      ) : (
        <div className="mx-auto flex w-full flex-wrap justify-start gap-2">
          {parentsProjects?.map((item, key: number) => (
            <Badge
              key={key}
              variant={item.name == filterActive ? "default" : "outline"}
              onClick={() => onFilterChange(item.name)}
              className={
                "cursor-pointer " +
                (item.name == filterActive ? classActive : "")
              }
            >
              {parentTexts?.find((ptt) => ptt.id === item.id)?.name ||
                item.name}
            </Badge>
          ))}
          <Badge
            onClick={() => onFilterChange("all")}
            variant={filterActive == "all" ? "default" : "outline"}
            className={
              "cursor-pointer " + ("all" == filterActive ? classActive : "")
            }
          >
            {t("Toutes")}
          </Badge>
        </div>
      )}
    </>
  );
};

export default AddProject;
