import { buttonVariants } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { MoveRight } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  const { t } = useTranslation("home");

  useEffect(() => {
    if (localStorage.getItem("code")) {
      setCode(localStorage.getItem("code"));
    }
  }, []);
  return (
    <div className="container">
      <div className="flex flex-wrap lg:items-center ">
        <div className="order-2 mb-6 basis-full md:mb-0 lg:order-1 lg:basis-2/5 lg:pr-8">
          <img
            src="/verdeo-home.jpg"
            alt="verdeo home"
            className="sticky top-10 lg:relative lg:top-0"
          />
        </div>
        <div className="order-1 mb-6 flex basis-full flex-col lg:order-2 lg:basis-3/5 lg:pl-8">
          <h1 className="-order-10 mb-5 text-2xl font-bold">
            {t("Soumettez")}{" "}
            <span className="text-primary">{t("un projet")} </span>{" "}
            {t("régénératif pour obtenir un soutien financier")}
          </h1>
          <p>
            {t(
              "Vous avez un projet à fort impact régénératif, déjà bien défini et chiffré, mais vous manquez de soutien financier pour le concrétiser ?"
            )}
          </p>
          <p>
            {t(
              "Verdeo est une plateforme dédiée aux porteurs de projets régénératifs, offrant non seulement un espace pour décrire et présenter vos initiatives, mais aussi des outils gratuits pour assurer la traçabilité et le suivi de vos projets dans le temps."
            )}
          </p>
          <p>
            {t(
              "Cet espace est ouvert à tous les acteurs engagés dans la régénération des écosystèmes, quel que soit le secteur ou le type de projet :"
            )}
          </p>
          <ul className="ml-8 list-disc">
            <li className="mb-2">
              <strong>{t("Associations")}</strong>{" "}
              {t(
                "cherchant à dédier moins de temps à la recherche de fonds et plus à leurs actions concrètes."
              )}
            </li>
            <li className="mb-2">
              <strong>{t("Agriculteurs")}</strong>{" "}
              {t(
                "souhaitant adopter des pratiques régénératives sans produits chimiques et nécessitant un financement pour faciliter leur transition."
              )}
            </li>
            <li className="mb-2">
              <strong>
                {t("Entrepreneurs, particuliers ou collectivités")}
              </strong>{" "}
              {t(
                "avec des projets écologiques innovants qui ont besoin d'un coup de pouce financier ponctuel."
              )}
            </li>
          </ul>
          <p>
            {t(
              "En vous inscrivant sur Verdeo, vous bénéficiez également d'outils gratuits pour assurer la traçabilité de vos projets, du dépôt jusqu'à la mise en œuvre. Nous nous occupons de trouver les financements adaptés, vous vous concentrez sur l'impact régénératif."
            )}
          </p>
          <div className="-order-9 mb-5 flex flex-wrap text-center lg:order-10 lg:mb-0 lg:mt-8">
            <Input
              className="mr-4 w-3/5 lg:w-[400px]"
              name="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder={t("Code d'accès")}
            />
            <div
              onClick={(e) => {
                e.preventDefault();
                if (code === import.meta.env.VITE_API_CODE) {
                  localStorage.setItem("code", code);
                  navigate("/login");
                }
              }}
              className={buttonVariants({
                variant: "default",
                className: `group ${
                  code !== import.meta.env.VITE_API_CODE
                    ? "disabled opacity-60 cursor-not-allowed"
                    : "cursor-pointer"
                }`,
              })}
            >
              {t("Suivant")}{" "}
              <MoveRight className="ml-2 transition-all group-hover:ml-4" />
            </div>
            <div className="mt-2 w-full text-left">
              <a
                target="_blank"
                className="text-sm text-primary hover:underline"
                href="https://verdeo.fr/contact/"
              >
                {t("Demander un code d’accès")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
