import { toast } from "@/components/ui/use-toast";
import { useQuery } from "@tanstack/react-query";

interface UseFetchDataReturn<T> {
  data: T | null;
  loading: boolean;
  error: string | null;
  fetchData: (uri: string) => Promise<T>;
}

export function useFetchDataV2<T>(
  endpoint: string,
  id?: string
): UseFetchDataReturn<T> {
  const fetchWithToken = async (token: string) => {
    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json();
  };

  const fetchWithTokenMagic = async (token: string, uri: string) => {
    const response = await fetch(uri, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) throw new Error("No refresh token available");

    const response = await fetch(
      `${import.meta.env.VITE_API_ENDPOINT}/token/refresh`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${refreshToken}`,
        },
        body: JSON.stringify({ refresh_token: refreshToken }),
      }
    );

    if (!response.ok) throw new Error("Failed to refresh token");

    const data = await response.json();
    const newToken = data.token;
    const newRefreshToken = data.refresh_token;

    localStorage.setItem("jwtToken", newToken);
    localStorage.setItem("refreshToken", newRefreshToken);

    return newToken;
  };

  // Inclure `id` dans la clé pour différencier les requêtes
  const { data, error, isLoading } = useQuery<T, Error>({
    queryKey: [endpoint, id], // Ajout de `id` dans la clé
    queryFn: async () => {
      let token = localStorage.getItem("jwtToken");
      if (!token) throw new Error("No token available");

      let response = await fetchWithToken(token);

      if (!response) {
        token = await refreshToken();
        response = await fetchWithToken(token);
      }

      return response;
    },
    retry: false, // ne pas réessayer automatiquement sur erreur
    enabled: !!endpoint, // Ne pas exécuter si `endpoint` est vide
  });

  const fetchData = async (uri: string) => {
    try {
      let token = localStorage.getItem("jwtToken");
      if (!token) throw new Error("No token available");

      let response = await fetchWithTokenMagic(token, uri);

      if (response.status === 401) {
        // Le token est expiré, essayez de le renouveler
        token = await refreshToken();

        if (token) {
          response = await fetchWithTokenMagic(token, uri);

          if (response.status === 401) {
            toast({
              title:
                "Votre session a expiré, veuillez vous connecter à nouveau.",
            });
            window.location.href = "/logout";
          }
        } else {
          toast({
            title: "Votre session a expiré, veuillez vous connecter à nouveau.",
          });
          window.location.href = "/logout";
        }
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (err: any) {
      return false;
    }
  };

  return {
    data,
    loading: isLoading,
    error: error?.message || null,
    fetchData,
  };
}
