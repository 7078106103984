import ReactBreadcrumb from "@/components/breadcrumb/Breadcrumb";

const Dashboard = () => {
  return (
    <>
      <h1 className="mb-5 text-2xl font-medium">Tableau de bord</h1>
      <ReactBreadcrumb
        items={[
          {
            href: "/dashboard",
            name: "Dashboard",
          },
        ]}
      />
      <h2 className="mt-5 text-lg">Bienvenue sur votre Tableau de Bord</h2>
      <p>
        Bonjour et bienvenue dans votre espace de travail ! Ici, vous pourrez
        suivre en temps réel l'évolution de vos données, consulter des
        graphiques détaillés, et obtenir un aperçu complet de vos performances.
      </p>
    </>
  );
};

export default Dashboard;
