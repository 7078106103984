import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useToast } from "@/components/ui/use-toast";
import { projectStatus } from "@/constants/constants";
import usePostData from "@/hooks/usePostData";
import { cn } from "@/libs/utils";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type ProjectSummaryType = {
  id: number;
  title: string;
  picture?: string;
  amount?: string;
  type?: string;
  link: string;
  className?: string;
  status: number;
  typeProjectImage: string | null;
  onSelect: () => void;
};
const ProjectSummary: FC<ProjectSummaryType> = ({
  id,
  title,
  picture,
  type,
  link,
  className,
  status,
  typeProjectImage,
  onSelect,
}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const { postData } = usePostData<any>();
  const { toast } = useToast();
  // const { typesProject } = useTypeProjectContext();
  // const typeProject = typesProject?.find((t) => {
  //   const slug = slugify(t.name, {
  //     lower: true,
  //     remove: /[*+~.()'"!:@]/g,
  //   });

  //   return slug === type;
  // });

  const { t } = useTranslation("submit", {
    keyPrefix: "project",
  });

  return (
    <>
      <div>
        <Link
          onClick={(e) => {
            e.preventDefault();
            onSelect();
          }}
          to={link}
          className={cn(
            "aspect-[250/200] h-[170px] rounded-[15px] overflow-hidden relative flex w-full",
            className
          )}
        >
          <div className="absolute left-0 top-0 size-full bg-slate-700">
            {picture ? (
              <img
                src={picture}
                alt={title}
                className="size-full object-cover"
              />
            ) : typeProjectImage ? (
              <img
                src={import.meta.env.VITE_MEDIA_BASE + typeProjectImage}
                alt="image en attente"
                className="size-full object-cover"
              />
            ) : (
              <img
                src="/pexels-pixabay-235925.jpg"
                alt="image en attente"
                className="size-full object-cover"
              />
            )}
          </div>
          <Status status={status} />
          <img
            src="/folder.svg"
            alt="picto folder"
            className="absolute left-3 top-4 w-5"
          />
          <div className="absolute right-4 top-4">
            <DropdownMenu>
              <DropdownMenuTrigger asChild className="cursor-pointer">
                <svg
                  className="float-right w-6 cursor-pointer text-white [text-shadow:_1px_1px_3px_rgb(0_0_0_/_80%)]"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  {" "}
                  <circle cx="12" cy="12" r="1" />{" "}
                  <circle cx="12" cy="5" r="1" />{" "}
                  <circle cx="12" cy="19" r="1" />
                </svg>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuGroup>
                  <DropdownMenuItem
                    className="cursor-pointer hover:bg-white focus:bg-white focus:text-black"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenDuplicate(true);
                    }}
                  >
                    <span>{t("Dupliquer")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="cursor-pointer hover:bg-white focus:bg-white focus:text-black"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenDelete(true);
                    }}
                  >
                    <span>{t("Supprimer")}</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </Link>
        <div className="flex flex-col justify-end pt-4">
          <h2
            title={title}
            className="line-clamp-2 text-base font-normal leading-5"
          >
            <Link
              to={link}
              onClick={(e) => {
                e.preventDefault();
                onSelect();
              }}
            >
              {title}
            </Link>
          </h2>
        </div>
      </div>
      <Dialog open={openDuplicate} onOpenChange={setOpenDuplicate}>
        <DialogContent className="sm:max-w-[600px]">
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              setLoadingDuplicate(true);
              const res = await postData({
                url: `${
                  import.meta.env.VITE_IP_API
                }/api/project/duplicate/${id}`,
                data: {},
              });

              if (
                res === null ||
                res.success === undefined ||
                res.success === null ||
                !res.success
              ) {
                toast({
                  className: "text-red-500",
                  title: t(
                    "Une erreur est survenue, le projet n'a pas été dupliqué."
                  ),
                  duration: 1000
                });
                setLoadingDuplicate(false);
                setOpenDuplicate(false);
                return;
              }

              toast({
                title: t("Le projet a bien été dupliqué."),
                duration: 1000
              });
              setLoadingDuplicate(false);
              setOpenDuplicate(false);
              window.location.reload();
            }}
            className="space-y-6"
          >
            <DialogHeader>
              <DialogTitle className="text-2xl">
                {t("Dupliquer un projet")}
              </DialogTitle>
              <DialogDescription>
                {t("Voulez-vous vraiment dupliquer ce projet ?")}
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button type="submit" disabled={loadingDuplicate}>
                {t("Oui")}
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setOpenDuplicate(false);
                }}
                className="bg-slate-500"
              >
                {t("Annuler")}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={openDelete} onOpenChange={setOpenDelete}>
        <DialogContent className="sm:max-w-[600px]">
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              setLoadingDelete(true);
              const res = await postData({
                url: `${import.meta.env.VITE_IP_API}/api/project/delete/${id}`,
                data: {},
              });

              if (
                res === null ||
                res.success === undefined ||
                res.success === null ||
                !res.success
              ) {
                toast({
                  className: "text-red-500",
                  title: t(
                    "Une erreur est survenue, le projet n'a pas été supprimé."
                  ),
                  duration: 1000
                });
                setLoadingDelete(false);
                setOpenDelete(false);
                return;
              }

              toast({
                title: t("Le projet a bien été supprimé."),
                duration: 1000
              });
              setLoadingDelete(false);
              setOpenDelete(false);
              window.location.reload();
            }}
            className="space-y-6"
          >
            <DialogHeader>
              <DialogTitle className="text-2xl">
                {t("Supprimer un projet")}
              </DialogTitle>
              <DialogDescription>
                {t("Voulez-vous vraiment supprimer ce projet ?")}
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button type="submit" disabled={loadingDelete}>
                {t("Oui")}
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setOpenDelete(false);
                }}
                className="bg-slate-500"
              >
                {t("Annuler")}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Status: FC<{ status: number }> = ({ status }) => {
  let statusText = "";
  let bg = "background";
  let text = "foreground";

  const { t } = useTranslation("submit", {
    keyPrefix: "project",
  });

  switch (status) {
    case projectStatus.NotStarted:
      statusText = t("Non commencé");
      bg = "background";
      text = "foreground";
      break;
    case projectStatus.InProgress:
      statusText = t("En cours");
      bg = "[#FF9500]";
      text = "background";
      break;
    case projectStatus.AwaitingValidation:
      statusText = t("En attente de validation");
      bg = "[#2710D8]";
      text = "background";
      break;
    case projectStatus.Validated:
      statusText = t("Validé");
      bg = "[#00973C]";
      text = "background";
      break;
    case projectStatus.Rejected:
      statusText = t("Refusé");
      bg = "[#CE3030]";
      text = "background";
      break;
    default:
      break;
  }
  return (
    <>
      <div
        hidden
        className="bg-[#00973C] bg-[#2710D8] bg-[#CE3030] bg-[#FF9500]"
      ></div>
      <div
        className={`bg-${bg} text-${text} absolute bottom-3 left-3 z-10 rounded-full px-2 py-1 text-xs`}
      >
        {statusText}
      </div>
    </>
  );
};
export default ProjectSummary;
