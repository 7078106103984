import { useAuth } from "@/auth/hooks/useAuth";
import { langs } from "@/constants/constants";
import { ChevronDown, LogOut, Mail, User2, UserPen } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import usePostData from "@/hooks/usePostData";
import { useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import { LoadingSpinner } from "../ui/loadingSpinner";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { useToast } from "../ui/use-toast";

const Navigation = () => {
  // const { isAuthenticated, logout } = useAuth();
  const { user, logout, isAuthenticated, setAuthState } = useAuth();

  const [openEditProfil, setOpenEditProfil] = useState(false);

  const [firstname, setFirstname] = useState(user?.firstname);
  const [lastname, setLastname] = useState(user?.lastname);
  const [structureName, setStructureName] = useState(user?.structureName);
  const [email, setEmail] = useState(user?.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loadingEditProfil, setLoadingEditProfil] = useState(false);

  const { postData } = usePostData<any>();

  const { i18n } = useTranslation();
  const { t } = useTranslation("general");

  const { toast } = useToast();

  useEffect(() => {
    setFirstname(user?.firstname);
    setLastname(user?.lastname);
    setStructureName(user?.structureName);
    setEmail(user?.email);
  }, [user]);

  const handleEditProfil = async () => {
    setLoadingEditProfil(true);

    const res = await postData({
      url: `${import.meta.env.VITE_IP_API}/api/profil`,
      data: {
        firstname,
        lastname,
        structureName,
        email,
        password,
        confirmPassword,
      },
    });
    if (res === null) {
      setLoadingEditProfil(false);
      return;
    }

    setAuthState({ user: res, loading: false, error: null });

    setLoadingEditProfil(false);

    toast({
      title: "Votre profil a bien été modifié.",
      duration: 1000
    });

    setOpenEditProfil(false);
  };

  return (
    <div className="container mb-8">
      <nav className="relative flex h-16 items-center border-b md:h-24">
        <ul className="flex w-full items-center">
          <li>
            <a href="https://verdeo.fr">
              <img
                src="/logo.svg"
                alt="logo verdeo"
                width={138}
                className="w-24 md:w-[138px]"
              />
            </a>
          </li>
          <li className="ml-auto mr-4">
            <DropdownMenu>
              <DropdownMenuTrigger asChild className="cursor-pointer">
                <div className="flex items-center">
                  {i18n === undefined ||
                  i18n === null ||
                  i18n.language === undefined ||
                  i18n.language === null
                    ? "FR"
                    : i18n.language.split("-")[0].toUpperCase()}
                  {/* <span className={"fi fi-"+(i18n.language === "en" ? "gb" : (i18n.language === "fr-FR" ? "fr" : i18n.language))} style={{
                    width: '45px',
                    height: '25px',
                    backgroundSize: 'contain',
                  }}></span> */}
                  <ChevronDown className="size-4 opacity-50" />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="min-w-[10px]">
                <DropdownMenuGroup className="w-[50px]">
                  {langs.map((lang, key) => (
                    <DropdownMenuItem
                      className="cursor-pointer hover:bg-white focus:bg-white focus:text-black"
                      onClick={() => {
                        i18n.language !== lang && i18n.changeLanguage(lang);
                      }}
                      key={key}
                    >
                      {lang.toUpperCase()}
                      {/* <span className={"fi fi-"+(lang === "en" ? "gb" : lang)+(i18n.language === lang ? " opacity-70 cursor-not-allowed" : "")} style={{
                                width: '45px',
                                height: '25px',
                                backgroundSize: 'contain',
                              }}></span> */}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </li>
          <li className="mr-4 text-right">
            <a
              href="https://verdeo.fr/contact/"
              className="group relative flex size-[42px] items-center justify-center rounded-full border border-foreground md:size-max md:border-0"
            >
              <span className="hidden text-xs md:block md:text-base">
                {t("Contactez-nous")}!{" "}
              </span>
              <span className="absolute left-0 top-full hidden h-px w-0 bg-foreground transition-all group-hover:w-full md:block"></span>
              <Mail className="block stroke-1 md:hidden" />
            </a>
          </li>
          {!isAuthenticated ? (
            <li>
              <Link
                to="/login"
                className="flex items-center justify-center rounded-[4rem] border-2 border-foreground bg-black p-2 transition-all hover:bg-foreground hover:text-background md:bg-background md:px-4"
              >
                <span className="hidden md:block">{t("Connexion")}</span>
                <User2 className="block stroke-1 text-background md:hidden" />
              </Link>
            </li>
          ) : (
            <li className="pl-5">
              <Popover>
                <PopoverTrigger asChild>
                  <div className="flex cursor-pointer items-center gap-1 text-foreground">
                    {user ? (
                      <>
                        <User2 />
                        {user?.firstname} {user?.lastname}
                      </>
                    ) : (
                      <LoadingSpinner />
                    )}
                  </div>
                </PopoverTrigger>
                <PopoverContent className="w-max">
                  <div className="mb-2 flex items-center gap-1">
                    <User2 className="text-[#626262]" />
                    <div>
                      <p className="m-0 text-xs text-[#626262]">
                        Connecté en tant que
                      </p>
                      <p className="m-0 text-[#626262]">
                        {user?.firstname} {user?.lastname}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <AlertDialog
                    open={openEditProfil}
                    onOpenChange={setOpenEditProfil}
                  >
                    <AlertDialogTrigger asChild>
                      <div className="flex cursor-pointer items-center gap-2 py-2 text-[#878787] transition-all hover:text-[#555]">
                        <UserPen size={20} />
                        Profil
                      </div>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>
                          {t("Modifier mon profil")}
                        </AlertDialogTitle>
                      </AlertDialogHeader>
                      <div className="mt-3">
                        <div className="space-y-2">
                          <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                            {t("Nom")}
                          </label>
                          <input
                            className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                            type="text"
                            name="name"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                          />
                        </div>
                        <div className="mt-4 space-y-2">
                          <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                            {t("Prènom")}
                          </label>
                          <input
                            className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                            type="text"
                            name="firstname"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                          />
                        </div>
                        <div className="mt-4 space-y-2">
                          <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                            {t("Nom de la structure")}
                          </label>
                          <input
                            className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                            type="text"
                            name="structureName"
                            value={structureName}
                            onChange={(e) => setStructureName(e.target.value)}
                          />
                        </div>
                        <div className="mt-4 space-y-2">
                          <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                            {t("Email")}
                          </label>
                          <input
                            className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="mt-4 space-y-2">
                          <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                            {t("Mot de passe")}
                          </label>
                          <input
                            className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                            type="password"
                            name="password"
                            autoComplete="new-password"
                            autoCorrect="off"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="mt-4 space-y-2">
                          <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                            {t("Confirmation mot de passe")}
                          </label>
                          <input
                            className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                            type="password"
                            name="confirmPassword"
                            autoComplete="new-password"
                            autoCorrect="off"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <AlertDialogFooter>
                        <AlertDialogCancel>{t("Annuler")}</AlertDialogCancel>
                        <AlertDialogAction
                          disabled={loadingEditProfil}
                          onClick={(e) => {
                            e.preventDefault();
                            handleEditProfil();
                          }}
                        >
                          {t("Sauvegarder")}
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>

                  <hr />
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <div className="flex cursor-pointer items-center gap-2 py-2 text-[#878787] transition-all hover:text-destructive">
                        <LogOut size={20} />
                        {t("Déconnexion")}
                      </div>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>
                          {t("Êtes-vous sûr de vouloir vous déconnecter ?")}
                        </AlertDialogTitle>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Annuler</AlertDialogCancel>
                        <AlertDialogAction
                          onClick={() => logout(window.location.href)}
                        >
                          {t("Se déconnecter")}
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </PopoverContent>
              </Popover>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
