import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { toast } from "@/components/ui/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { Eye, EyeOff } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const LoginForm = () => {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const navigate = useNavigate();
  const { signIn, user } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [formIsLoading, setFormIsLoading] = useState(false);
  const { t } = useTranslation("auth", {
    keyPrefix: "login",
  });

  const formSchema = z.object({
    email: z.string().email({ message: t("Adresse email invalide") }),
    password: z.string().min(2, {
      message: t("Le mot de passe doit contenir au moins 2 caractères."),
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    if(user !== null && user?.status === 1) {
      window.location.href = `/dashboard`
    } /*else if (redirect) {
      window.location.href = redirect;
    }*/ else if(user !== null) {
      window.location.href = `/submit/programs`
    }
  }, [user]);

  const { setError } = form;

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setFormIsLoading(true);

    const { success } = await signIn(values.email, values.password);
    if (success) {
      toast({
        title: "Vous êtes connecté",
      });
      // if(user?.status === 1) {
      //   window.location.href = `/dashboard`
      // } /*else if (redirect) {
      //   window.location.href = redirect;
      // } else*/ {
      //   navigate(`/submit/programs`);
      //   window.location.reload();
      // }
    } else {
      // Déclenche une erreur pour le champ mot de passe
      setError("password", {
        type: "server",
        message: t("Email ou mot de passe incorrect"),
      });
    }
    setFormIsLoading(false);
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Déjà un compte ?")}</CardTitle>
        <CardDescription>
          {t("Accédez à votre espace et gérez vos projets.")}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("Mail")}</FormLabel>
                  <FormControl>
                    <Input placeholder={t("Mail")} {...field} type="email" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("Mot de passe")}</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input
                        placeholder={t("Mot de passe")}
                        {...field}
                        type={showPassword ? "text" : "password"}
                      />
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPassword(!showPassword);
                        }}
                        className="absolute right-2 top-1/2 flex size-5 -translate-y-1/2 cursor-pointer items-center justify-center"
                      >
                        {!showPassword ? <EyeOff /> : <Eye />}
                      </div>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end">
              <Link to="/forgot-password" className="underline">
                {t("Mot de passe oublié ?")}
              </Link>
            </div>
            <Button type="submit" variant={"default"} className="w-full">
              {formIsLoading ? <LoadingSpinner /> : t("Se connecter")}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};

export default LoginForm;
